import { LicenseManager } from '@ag-grid-enterprise/core';
import { ApolloProvider } from '@apollo/client';
import { Authenticator } from '@fullcontour/auth';
import {
  ErrorBoundry,
  MediaContextProvider,
  RootLoader,
  ScrollToTop,
  apolloClient,
} from '@fullcontour/common';
import '@fullcontour/common/src/config/i18n/i18next';
import { Amplify } from 'aws-amplify';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import awsAuthConfig from './config/amplify/awsAuthConfig';
import './index.scss';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068050}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{3Shape_Design_Services}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{3DS}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{3DS}_need_to_be_licensed___{3DS}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{3_December_2025}____[v3]_[01]_MTc2NDcyMDAwMDAwMA==60be0b9f2e82bc1c68b2494790586a95',
);

const ampConfig = awsAuthConfig[import.meta.env.VITE_ENV];
// const analyticsConfig = awsAnalyticsConfig[import.meta.env.VITE_ENV];

Amplify.configure({ ...ampConfig });
// Analytics.configure(analyticsConfig);

if (import.meta.env.VITE_ENV !== 'production') {
  localStorage.setItem('debug', 'customer-portal:*');
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ApolloProvider client={apolloClient}>
    <Suspense fallback={<RootLoader loading />}>
      <ErrorBoundry>
        <BrowserRouter>
          <ScrollToTop>
            <MediaContextProvider>
              <Authenticator>
                <App client={apolloClient} />
              </Authenticator>
            </MediaContextProvider>
          </ScrollToTop>
        </BrowserRouter>
      </ErrorBoundry>
    </Suspense>
  </ApolloProvider>,
);
