import { useReactiveVar } from '@apollo/client';
import {
  CheckboxField,
  FieldContainer,
  TurnaroundDropdown,
  getHelpers,
} from '@fullcontour/common';
import { FastField, Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

function TempOrderCaseFields({
  values,
  forceDesignApproval,
  turnaroundTimes,
  defaultTurnaroundTime,
  pendingCases = false,
}) {
  const { id } = values;

  const { handleBlur } = useFormikContext();

  const helpFuncsData = useReactiveVar(getHelpers);

  const approvalRequired = forceDesignApproval;

  const { t } = useTranslation('orders');
  return (
    <>
      <FieldContainer name={`name${id}`} label="Name">
        <Field
          id={`name${id}`}
          name={`name${id}`}
          placeholder="Name"
          size="mini"
          className="input"
        />
      </FieldContainer>
      <Field
        id={pendingCases ? `turnaroundTimeId${values.id}` : 'turnaroundTimeId'}
        name={
          pendingCases ? `turnaroundTimeId${values.id}` : 'turnaroundTimeId'
        }
        required
        component={TurnaroundDropdown}
        defaultTurnaroundTime={defaultTurnaroundTime}
        turnaroundTimes={turnaroundTimes}
        size="mini"
      />
      <FieldContainer name="instructions" label="Additional Instructions">
        <FastField
          id={pendingCases ? `instructions${values.id}` : 'instructions'}
          name={pendingCases ? `instructions${values.id}` : 'instructions'}
          placeholder="Instructions"
          component="textarea"
          className="input"
          rows="1"
          style={{ resize: 'vertical' }}
          size="mini"
          onBlur={(data) => {
            handleBlur(data);
            helpFuncsData?.helpFuncs?.next();
          }}
        />
      </FieldContainer>
      <span
        className="has-tooltip-top has-tooltip-arrow has-tooltip-multiline"
        data-tooltip={
          approvalRequired
            ? t(
                'Design approval is forced for at least one of the selected design types',
              )
            : null
        }
      >
        <Field
          name={`requiresDesignApproval${id}`}
          component={CheckboxField}
          labelText={t('Require Design Approval?')}
          size="is-small"
          disabled={
            !values?.orderItems?.some((item) => item.designTypeId) ||
            forceDesignApproval
          }
          required={approvalRequired}
          style={{ marginTop: '1em' }}
        />
      </span>
    </>
  );
}

TempOrderCaseFields.propTypes = {
  values: PropTypes.object.isRequired,
  forceDesignApproval: PropTypes.bool.isRequired,
  turnaroundTimes: PropTypes.array.isRequired,
  defaultTurnaroundTime: PropTypes.object.isRequired,
  pendingCases: PropTypes.bool,
};

export default memo(TempOrderCaseFields);
