function initialValues(props) {
  const { customerUserData: { customerUser = {} } = {}, currentUser } = props;

  let locationId;
  if (currentUser?.scopeToLocation) {
    locationId = currentUser.locationId;
  } else {
    locationId = customerUser.location ? customerUser.location.id : '';
  }

  return {
    id: customerUser.id || null,
    firstName: customerUser.firstName || '',
    lastName: customerUser.lastName || '',
    organizationId: customerUser.organization
      ? customerUser.organization.id
      : currentUser.organizationId,
    locationId,
    roleId: customerUser.role ? customerUser.role.id : '',
    language: customerUser.language || 'en',
    email: customerUser.email || '',
    phone: customerUser.phone || '',
    designTypeIds:
      !Array.isArray(customerUser.designTypeIds) ||
      customerUser.designTypeIds.length === 0
        ? []
        : customerUser.designTypeIds,
  };
}

export default initialValues;
