import {
  FormGridWrap,
  FormSection,
  LocationDependantDropdown,
  MultiSelectById,
  RoleSelect,
  UserFormCommonFields,
} from '@fullcontour/common';
import {
  GET_CUSTOMER_ORG_LOCS,
  GET_DESIGN_TYPES_BY_PRICE_LISTS_DROPDOWN,
} from '@fullcontour/shared-api';
import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

function UserFormCustomerInner({
  customerUserData: { customerUser } = {},
  setFieldValue = () => null,
  action = null,
  currentUser = {},
  values = {},
  match = {},
}) {
  const [selectedRole, setSelectedRole] = useState(customerUser?.role || null);

  const updateSelectedRole = (obj) => {
    setSelectedRole(obj);
    if (obj?.scopeToLocation === false) {
      setFieldValue('locationId', '');
    }
  };

  const shouldShowLocationDropdown = useMemo(
    () =>
      selectedRole?.scopeToLocation && currentUser?.scopeToLocation === false,
    [selectedRole, currentUser],
  );

  const shouldShowDesignTypes = useMemo(
    () =>
      selectedRole?.assignmentDesignTypes &&
      selectedRole?.scopeToLocation &&
      values.organizationId &&
      values.locationId,
    [selectedRole, values.organizationId, values.locationId],
  );

  const renderMultiSelect = (fieldArrayProps) => (
    <MultiSelectById
      {...fieldArrayProps}
      query={GET_DESIGN_TYPES_BY_PRICE_LISTS_DROPDOWN}
      variables={{
        labLocationIds: [values.locationId],
      }}
      label="Assigned Design Types"
      cellLabel="Design Types"
      dataName="designTypesByPriceLists"
    />
  );

  return (
    <FormGridWrap match={match}>
      <UserFormCommonFields />
      <FormSection title="Assignment">
        <Field
          name="roleId"
          required
          disabled={action === 'edit'}
          component={RoleSelect}
          setrole={updateSelectedRole}
          modelscope="User"
          locationUser={currentUser?.scopeToLocation}
        />
        {shouldShowLocationDropdown && (
          <Field
            name="locationId"
            required
            component={LocationDependantDropdown}
            placeholder="Select a Location"
            parentOrgId={values.organizationId}
            orgQuery={GET_CUSTOMER_ORG_LOCS}
            type="customer"
            disabled={action === 'edit'}
            labelText="Location"
          />
        )}
        {shouldShowDesignTypes && (
          <FieldArray name="designTypeIds" required>
            {renderMultiSelect}
          </FieldArray>
        )}
      </FormSection>
    </FormGridWrap>
  );
}

UserFormCustomerInner.propTypes = {
  customerUserData: PropTypes.shape({
    customerUser: PropTypes.shape({
      role: PropTypes.object,
    }),
  }),
  setFieldValue: PropTypes.func,
  action: PropTypes.string,
  match: PropTypes.object,
  currentUser: PropTypes.shape({
    scopeToLocation: PropTypes.bool,
  }),
  values: PropTypes.shape({
    organizationId: PropTypes.string,
    locationId: PropTypes.string,
  }),
};

export default UserFormCustomerInner;
