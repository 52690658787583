import { useApolloClient, useQuery } from '@apollo/client';
import {
  CurrentUserContext,
  LoadingOrError,
  withFormWrap,
} from '@fullcontour/common';
import { GET_CUSTOMER_USER } from '@fullcontour/shared-api';
import { Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import UserFormCustomerInner from './UserFormCustomerInner';
import { create, initialValues, schema, update } from './helpers/Customer';
import { getVariables, skipQuery } from './helpers/queryOptions';
function UserFormCustomer({
  initialValues = () => null,
  schema = () => null,
  match = {},
  separateId = null,
  setErrorMessages = () => null,
  clearErrorMessages = () => null,
  navigate = () => null,
}) {
  const { currentUser } = useContext(CurrentUserContext);

  const client = useApolloClient();
  const { action } = useParams();
  const {
    data: customerUserData,
    loading: customerUserLoading,
    error: customerUserError,
  } = useQuery(GET_CUSTOMER_USER, {
    skip: skipQuery ? skipQuery({ match }) : false,
    variables: getVariables ? getVariables({ match }) : {},
    fetchPolicy: 'cache-and-network',
  });
  async function handleSubmit(values, { setSubmitting }) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };

    if (separateId && match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }

    if (separateId) {
      variables.variables.input.input.id = undefined;
    }

    if (match.params.action && match.params.action === 'new') {
      await create({
        variables,
        client,
        setErrorMessages,
        clearErrorMessages,
        navigate,
      });
    } else {
      await update({
        variables,
        client,
        setErrorMessages,
        clearErrorMessages,
        navigate,
      });
    }
    setSubmitting(false);
  }
  if (customerUserLoading || customerUserError) {
    return (
      <LoadingOrError
        error={customerUserError}
        loading={customerUserLoading}
        title="User"
      />
    );
  }
  return (
    <Formik
      initialValues={initialValues({ customerUserData, currentUser })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <UserFormCustomerInner
          {...{
            values,
            action,
            customerUserData,
            setFieldValue,
            match,
            currentUser,
          }}
        />
      )}
    </Formik>
  );
}

UserFormCustomer.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  create: PropTypes.func,
  separateId: PropTypes.string,
  currentUser: PropTypes.object,
  setErrorMessages: PropTypes.func,
  clearErrorMessages: PropTypes.func,
  navigate: PropTypes.func,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  UserFormCustomer,
);
