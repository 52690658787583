import { TruncateText } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import TempOrderCaseFields from './TempOrderCaseFields';

function TempOrderCase(props) {
  const { values, defaultTurnaroundTime, pendingCases, turnaroundTimes } =
    props;

  const [caseFieldsOpen, setCaseFieldsOpen] = useState(true);

  function toggleCaseFields(evt) {
    evt.preventDefault();

    setCaseFieldsOpen((prevState) => !prevState);
  }

  return (
    <div
      className="column is-2 p-4"
      style={{ borderRight: '1px solid #ddd9d9' }}
    >
      <div className="is-flex is-justify-content-space-between">
        <h6
          className="title is-6 is-align-self-center m-0 p-0"
          style={{
            maxWidth: 'calc(100% - 40px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: ' ellipsis',
            width: 'calc(100% - 40px)',
            lineHeight: '26.25px',
          }}
        >
          <TruncateText text={values.intOrderId} />
        </h6>

        <button
          type="button"
          className="button is-small"
          onClick={toggleCaseFields}
        >
          <span className="icon">
            <i
              className={`bx ${
                caseFieldsOpen ? 'bx-chevron-up' : 'bx-chevron-down'
              }`}
            />
          </span>
        </button>
      </div>
      {caseFieldsOpen && (
        <div>
          <TempOrderCaseFields
            {...props}
            turnaroundTimes={turnaroundTimes}
            defaultTurnaroundTime={defaultTurnaroundTime}
            pendingCases={pendingCases}
          />
        </div>
      )}
    </div>
  );
}

TempOrderCase.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  turnaroundTimes: PropTypes.array.isRequired,
  defaultTurnaroundTime: PropTypes.object.isRequired,
  pendingCases: PropTypes.bool,
};

TempOrderCase.defaultProps = {
  pendingCases: false,
};

export default TempOrderCase;
